import { Player } from '@orangelv/contracts'
import {
  SvgElement,
  renderPiece,
  LoadSvg,
  LoadFont,
  DesignPart,
} from '@orangelv/svg-renderer'
import { Immutable, createExprEval, isDefined } from '@orangelv/utils'
import { Vector2, in2px, multiply2 } from '@orangelv/utils-geometry'
import { GetSize, Pattern, PieceMappingItem } from '@orangelv/utils-olvpf'

import { loadFonts } from '../../../platform/spectacle-base/common/fonts'
import {
  ColorRow,
  DesignPartRow,
  FontRow,
  CustomFontRow,
  PlacementRow,
  Recipe,
  VendorRow,
} from './types'
import { filterDesignParts } from '../../common/svg-renderer-utils'
import fullCustomOffsets from '../assets/full-custom-templates/offsets.json'
import assert from '../../assert'

const getFillType = (pieceName: string) => {
  switch (pieceName) {
    case 'front':
    case 'back':
    case 'left':
    case 'right':
    case 'back_top':
    case 'back_bottom':
    case 'shoulders_front':
    case 'shoulders_back':
      return 'bodyColor'

    case 'collar_right':
    case 'collar_back':
    case 'collar_left':
    case 'neckband':
      return 'collarColor'

    case 'sleeve_left':
    case 'sleeve_right':
    case 'armhole_band_left':
    case 'armhole_band_right':
    case 'sleeve_band_left':
    case 'sleeve_band_right':
      return 'sleevesColor'

    default:
      throw new Error(`Unhandled piece "${pieceName}"`)
  }
}

const CORNERS_CREW_FRONT = [
  'neckLeft',
  'armLeftTop',
  'armLeftBottom',
  'leftBottom',
  'rightBottom',
  'armRightBottom',
  'armRightTop',
  'neckRight',
]

const CORNERS_V_FRONT = ['middleTop', ...CORNERS_CREW_FRONT]

const CORNERS_MENS_TANK_FRONT = [
  'neckLeft',
  'armLeftTop',
  'armLeftBottom',
  'leftBottom',
  'rightBottom',
  'armRightBottom',
  'armRightTop',
  'neckRight',
]

const CORNERS_BACK = [
  'neckRight',
  'armRightTop',
  'armRightBottom',
  'rightBottom',
  'leftBottom',
  'armLeftBottom',
  'armLeftTop',
  'neckLeft',
]

const CORNERS_MENS_TANK_BACK = [
  'neckRight',
  'armRightTop',
  'armRightBottom',
  'rightBottom',
  'leftBottom',
  'armLeftBottom',
  'armLeftTop',
  'neckLeft',
]

const CORNERS_FLARED_SLEEVE = [
  'rightTop',
  null,
  'rightBottom',
  'leftBottom',
  null,
  'leftTop',
]

const CORNERS_FOUR = ['rightTop', 'rightBottom', 'leftBottom', 'leftTop']

const CORNERS_RIBBON_NECKBAND = [
  'rightTop',
  'rightMiddle',
  'rightBottom',
  'leftBottom',
  'leftMiddle',
  'leftTop',
]

// Pattern matching style
const getCornerNames = (patternName: string, pieceName: string) =>
  patternName === 'mensShortSleeve' ?
    pieceName === 'front' ? CORNERS_CREW_FRONT
    : pieceName === 'back' ? CORNERS_BACK
    : pieceName === 'sleeve_left' ? CORNERS_FLARED_SLEEVE
    : pieceName === 'sleeve_right' ? CORNERS_FLARED_SLEEVE
    : pieceName === 'neckband' ? CORNERS_FOUR
    : undefined
  : patternName === 'mensSleeveless' ?
    pieceName === 'front' ? CORNERS_CREW_FRONT
    : pieceName === 'back' ? CORNERS_BACK
    : pieceName === 'armhole_band_left' ? CORNERS_FOUR
    : pieceName === 'armhole_band_right' ? CORNERS_FOUR
    : pieceName === 'neckband' ? CORNERS_FOUR
    : undefined
  : patternName === 'mensTank' ?
    pieceName === 'front' ? CORNERS_MENS_TANK_FRONT
    : pieceName === 'back' ? CORNERS_MENS_TANK_BACK
    : pieceName === 'armhole_band_left' ? CORNERS_FOUR
    : pieceName === 'armhole_band_right' ? CORNERS_FOUR
    : undefined
  : patternName === 'womensLongSleeve' ?
    pieceName === 'front' ? CORNERS_V_FRONT
    : pieceName === 'back' ? CORNERS_BACK
    : pieceName === 'sleeve_left' ? CORNERS_FOUR
    : pieceName === 'sleeve_right' ? CORNERS_FOUR
    : pieceName === 'neckband' ? CORNERS_RIBBON_NECKBAND
    : undefined
  : patternName === 'womensShortSleeve' ?
    pieceName === 'front' ? CORNERS_V_FRONT
    : pieceName === 'back' ? CORNERS_BACK
    : pieceName === 'sleeve_left' ? CORNERS_FOUR
    : pieceName === 'sleeve_right' ? CORNERS_FOUR
    : pieceName === 'neckband' ? CORNERS_RIBBON_NECKBAND
    : undefined
  : patternName === 'womensTank' ?
    pieceName === 'front' ? CORNERS_CREW_FRONT
    : pieceName === 'back' ? CORNERS_BACK
    : pieceName === 'armhole_band_left' ? CORNERS_FOUR
    : pieceName === 'armhole_band_right' ? CORNERS_FOUR
    : undefined
  : undefined

function lookUpColor(
  colorDict: Record<string, ColorRow>,
  recipe: Recipe,
  key: keyof Recipe,
): ColorRow | undefined
function lookUpColor(
  colorDict: Record<string, ColorRow>,
  recipe: Recipe,
  key: keyof Recipe,
  isRequired: true,
): ColorRow
function lookUpColor(
  colorDict: Record<string, ColorRow>,
  recipe: Recipe,
  key: keyof Recipe,
  isRequired?: boolean,
): ColorRow | undefined {
  const id = recipe[key]
  if (id === undefined || id === null) {
    if (isRequired) throw new Error(`Required color ${key} is null`)
    return undefined
  }

  if (typeof id !== 'string') throw new Error(`Non-string color ${key}`)
  const color = colorDict[id] as ColorRow | undefined
  if (isRequired && !color) throw new Error(`Bad color id ${key}`)
  return color
}

const isTextPlacement = (placement: Immutable<PlacementRow>) =>
  ['teamName', 'playerName', 'playerNumber'].includes(placement.decoration)

const isImagePlacement = (placement: Immutable<PlacementRow>) =>
  ['brandLogo', 'teamLogo'].includes(placement.decoration)

const LETTER_SPACING_RATIO = 0.05

const normalizeAnchor = (anchor: PlacementRow['anchor']) => ({
  from: anchor?.from ?? 'center-center',
  to: anchor?.to ?? 'document-center',
  offset: { x: anchor?.offset?.x ?? 0, y: anchor?.offset?.y ?? 0 },
})

export async function renderPieceFromRecipe({
  patternPackage,
  recipe,
  pattern,
  patternName,
  pieceMappingItem,
  sizeName,
  getSize,
  player,
  loadSvg,
  loadFont,
  isFactoryMode = false,
  colorDict,
  designParts: allDesignParts,
  fonts,
  customFonts,
  placements,
  vendor,
}: {
  patternPackage: string
  recipe: Recipe
  pattern: Pattern
  patternName: string
  pieceMappingItem: PieceMappingItem
  sizeName: string
  getSize: GetSize
  player: Player
  loadSvg: LoadSvg
  loadFont: LoadFont
  isFactoryMode?: boolean
  colorDict: Record<string, ColorRow>
  designParts: Immutable<DesignPartRow[]>
  fonts: Immutable<FontRow[]>
  customFonts: Immutable<CustomFontRow[]>
  placements: Immutable<PlacementRow[]>
  vendor: Immutable<VendorRow>
}) {
  const pieceName = pieceMappingItem.name

  const relevantPlacements = placements.filter(
    (placement) =>
      placement.productId === patternName && placement.pieceName === pieceName,
  )

  // Run early, await late
  const sizePromise = getSize(
    patternPackage,
    pattern,
    patternName,
    pieceMappingItem,
    sizeName,
    getCornerNames(patternName, pieceName),
  )

  const fontEntities = await loadFonts(vendor, fonts, customFonts)

  const exprEval = createExprEval({ in2px })

  const teamName = recipe['teamName.text']
  const teamLogo = recipe['teamLogo']
  const teamLogoImageUrl = teamLogo && `/api/images/${teamLogo.id}`

  const textElements = relevantPlacements
    .filter((placement) => isTextPlacement(placement))
    .map((placement) => {
      const { decoration } = placement

      const textRaw =
        decoration === 'teamName' ? teamName
        : decoration === 'playerName' ? player.name
        : decoration === 'playerNumber' ? player.number
        : undefined

      const recipeKey =
        decoration === 'teamName' ? 'teamName'
        : decoration === 'playerName' ? 'players.nameStyle'
        : decoration === 'playerNumber' ? 'players.numberStyle'
        : undefined

      if (recipeKey === undefined) {
        return
      }

      const color = lookUpColor(
        colorDict,
        recipe,
        `${recipeKey}.color`,
        true,
      ).hex

      // There is no color if the placement isn't enabled in the first place
      if (
        recipeKey === undefined ||
        textRaw === undefined ||
        textRaw === null ||
        textRaw === '' ||
        !color
      ) {
        return
      }

      const fontId = recipe[`${decoration}.font`]
      if (typeof fontId !== 'string') throw new Error('No font')

      const outlineColor1 = lookUpColor(
        colorDict,
        recipe,
        `${recipeKey}.outline1Color`,
      )?.hex

      const outlineColor2 = lookUpColor(
        colorDict,
        recipe,
        `${recipeKey}.outline2Color`,
      )?.hex

      const fontEntity = fontEntities.find((x) => x.id === fontId)
      if (!fontEntity) {
        throw new Error(`Could not find font - '${fontId}'!`)
      }

      const size =
        placement.textSize === undefined ? 0 : exprEval(placement.textSize)

      if (typeof size !== 'number') {
        throw new Error(`Bad textSize: ${placement.textSize}`)
      }

      const scaleFactor =
        recipe[`${recipeKey}.scaleFactor.${pieceMappingItem.name}`] ??
        recipe[`${recipeKey}.scaleFactor`] ??
        1

      return {
        text: `"${textRaw}"`, // Goes through exprEval, that's why the quotes
        size,
        color,
        font: fontEntity.url,
        outlineColor1,
        outlineColor2,
        letterSpacing:
          LETTER_SPACING_RATIO *
          (outlineColor2 ? 2
          : outlineColor1 ? 1
          : 0),
        anchor: normalizeAnchor(placement.anchor),
        boundingBox: placement.boundingBox,
        scaleFactor,
        isVertical: placement.isTextVertical,
      }
    })
    .filter(isDefined)

  const imageElements: SvgElement[] = relevantPlacements
    .filter((placement) => isImagePlacement(placement))
    .map((placement) => {
      const { decoration } = placement

      let svg: SvgElement['svg'] | undefined
      if (decoration === 'brandLogo' && recipe['brandLogoColor']) {
        svg = {
          name: `"brandLogo"`,
          colors: {
            '*[id^="a-"]': lookUpColor(
              colorDict,
              recipe,
              'brandLogoColor',
              true,
            ).hex,
          },
        }
      } else if (decoration === 'teamLogo' && teamLogoImageUrl) {
        svg = {
          name: `"${teamLogoImageUrl}"`,
          colors: {},
        }
      }

      if (!svg) {
        return
      }

      return {
        svg,
        anchor: normalizeAnchor(placement.anchor),
        boundingBox: placement.boundingBox,
      }
    })
    .filter(isDefined)

  if (recipe['design.file']) {
    const offset: Vector2 | undefined =
      fullCustomOffsets[patternName]?.[pieceName]

    assert(offset !== undefined)

    imageElements.unshift({
      anchor: {
        from: 'top-left',
        to: 'document-center',
        offset: multiply2(-1, offset),
      },
      svg: {
        name:
          recipe['design.file'].id === '-' ?
            '"full-custom-example-design"' // For generate-icons
          : `"/api/images/${recipe['design.file'].id}"`,
        colors: {},
        extraAttributes: {
          // Illustrator removes CSS classes, but we can abuse IDs like hidden0, hidden1, hidden2 etc. which don't get removed.
          '[id^=hidden]': {
            visibility: 'hidden',
          },
        },
      },
    })
  }

  const designParts =
    recipe['design.design'] === undefined || recipe['design.design'] === null ?
      undefined
    : (filterDesignParts(
        allDesignParts,
        recipe['design.design'],
        pieceName,
        recipe['sku'],
      ) as DesignPart[])

  const designColor1 = lookUpColor(colorDict, recipe, 'design.color1')
  const designColor2 = lookUpColor(colorDict, recipe, 'design.color2')
  const designColor3 = lookUpColor(colorDict, recipe, 'design.color3')
  const designColor4 = lookUpColor(colorDict, recipe, 'design.color4')
  const designColors = Object.fromEntries(
    [
      designColor1 ? ['*[id^="a-"]', designColor1.hex] : undefined,
      designColor2 ? ['*[id^="b-"]', designColor2.hex] : undefined,
      designColor3 ? ['*[id^="c-"]', designColor3.hex] : undefined,
      designColor4 ? ['*[id^="d-"]', designColor4.hex] : undefined,
    ].filter(isDefined),
  )

  const fill =
    lookUpColor(colorDict, recipe, `${getFillType(pieceName)}`)?.hex ??
    '#ffffff'

  return renderPiece(await sizePromise, loadSvg, loadFont, {
    fill,
    textElements,
    imageElements,
    designParts,
    designColors,
    factory:
      isFactoryMode ?
        {
          markingOuterColor: '#f2f3fa',
          markingInnerColor: '#000000',
          notchOuterColor: '#f2f3fa',
          notchInnerColor: '#000000',
          cutLineOuterColor: '#000000',
          cutLineInnerColor: '#dbdcd9',
        }
      : undefined,
  })
}
