type Props = {
  children: React.ReactNode
  section: string
}

const TileSection = (props: Props) => {
  const { children, section } = props
  return (
    <div className="flex justify-end" data-section={section}>
      <div className="sticky top-[10px] mb-[30px] mt-[10px] inline-block self-start align-top">
        <div className="rounded-[8px] bg-white/70 px-[16px] py-[8px] text-[24px] font-bold">
          {section}
        </div>
      </div>
      <div className="pointer-events-auto inline-block px-[32px] pb-[24px] align-top">
        {children}
      </div>
    </div>
  )
}

export default TileSection
