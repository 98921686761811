// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "vermelho",
    "name": "Highway Vermelho",
    "colors": 4,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "verde",
    "name": "Highway Verde",
    "colors": 4,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "azul",
    "name": "Highway Azul",
    "colors": 4,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "amarelo",
    "name": "Highway Amarelo",
    "colors": 4,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "fog",
    "name": "Fog",
    "colors": 2,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "nova",
    "name": "Nova",
    "colors": 3,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  },
  {
    "id": "fullCustom",
    "name": "Full Custom",
    "colors": 0,
    "limitations": {
      "productIds": [
        "mensShortSleeve"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignRow[]>> = data;

export type Design = typeof typed[number];

export const DESIGN_INDEX_KEY = "id";
export type DesignIndexKey = "id";
export type DesignId = Design["id"];

let i = 0;
export const DESIGN_DICT = {
  "vermelho": typed[i++],
  "verde": typed[i++],
  "azul": typed[i++],
  "amarelo": typed[i++],
  "fog": typed[i++],
  "nova": typed[i++],
  "fullCustom": typed[i++]
} as const;

export { typed as DESIGNS };
