import cn from 'classnames'

type Props = {
  isSelected: boolean
}

const RadioButton = (props: Props) => {
  const { isSelected } = props
  return (
    <div
      className={cn(
        'h-[24px] w-[24px] cursor-pointer rounded-full border-[2px]',
        isSelected ? 'border-orange' : 'border-[#ccc]',
      )}
    >
      <div
        className={cn(
          'm-[3px] h-[14px] w-[14px] rounded-full bg-orange opacity-0',
          isSelected && 'opacity-100',
        )}
      />
    </div>
  )
}

export default RadioButton
