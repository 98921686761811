// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ProductRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "shirt",
    "name": "Jersey",
    "isEnabled": true,
    "fabrics": [
      "grid",
      "mockMesh",
      "stretchMesh"
    ],
    "colorAreas": [
      "body",
      "sleeves",
      "collar"
    ],
    "sizes": [
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL"
    ],
    "defaultSize": "M",
    "isRosterEnabled": true
  },
  {
    "id": "mettaMensShortSleeve",
    "name": "Metta",
    "isEnabled": true,
    "fabrics": [
      "grid",
      "mockMesh",
      "stretchMesh"
    ],
    "colorAreas": [
      "body",
      "sleeves",
      "collar"
    ],
    "sizes": [
      "M"
    ],
    "defaultSize": "M",
    "isRosterEnabled": false
  },
  {
    "id": "418",
    "name": "Teapot",
    "isEnabled": false,
    "isRosterEnabled": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ProductRow[]>> = data;

export type Product = typeof typed[number];

export const PRODUCT_INDEX_KEY = "id";
export type ProductIndexKey = "id";
export type ProductId = Product["id"];

let i = 0;
export const PRODUCT_DICT = {
  "shirt": typed[i++],
  "mettaMensShortSleeve": typed[i++],
  "418": typed[i++]
} as const;

export { typed as PRODUCTS };
