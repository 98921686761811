import ky from 'ky'

import { Immutable } from '@orangelv/utils'

import getAsset from '../../getAsset'
import { VendorRow, FontRow, CustomFontRow } from './types'

type FontEntity = {
  id: string
  url: string
  menuUrl?: string
  provider: string
  family: string
  variant: string
  variantName: string
  parentId?: FontEntity['id']
}

type GoogleFont = google.fonts.WebfontFamily & { menu: string }

const VARIANT_NAMES = {
  '100': 'Thin',
  '200': 'Extra Light',
  '300': 'Light',
  regular: 'Regular',
  '500': 'Medium',
  '600': 'Semi Bold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Black',
  '100italic': 'Thin Italic',
  '200italic': 'Extra Light Italic',
  '300italic': 'Light Italic',
  italic: 'Regular Italic',
  '500italic': 'Medium Italic',
  '600italic': 'Semi Bold Italic',
  '700italic': 'Bold Italic',
  '800italic': 'Extra Bold Italic',
  '900italic': 'Black Italic',
}

const state: {
  promise: undefined | Promise<FontEntity[]>
  fontEntities: FontEntity[]
} = {
  promise: undefined,
  fontEntities: [],
}

const parseId = (fontId: string) => {
  const [provider, familyAndVariant] = fontId.split(':', 2)
  if (!provider || !familyAndVariant) {
    throw new Error(
      `Could not parse provider/familyAndVariant from '${fontId}'!`,
    )
  }
  const [family, variant] = familyAndVariant.split('/', 2)
  if (!family || !variant) {
    throw new Error(
      `Could not parse family/variant from '${familyAndVariant}'!`,
    )
  }
  return { provider, family, variant }
}

const customFontsProvider = async (
  customFonts: Immutable<CustomFontRow[]>,
): Promise<FontEntity[]> => {
  const entities = customFonts.map((x) => {
    const fontId = x.id
    const { provider, family, variant } = parseId(fontId)
    return {
      id: fontId,
      url: getAsset(`fonts/${family}-${variant}.${x.filetype}`),
      provider,
      family,
      variant,
      variantName: VARIANT_NAMES[variant],
    }
  })

  return entities.map((entity) => {
    const defaultVariant =
      entities.find(
        (x) => x.family === entity.family && x.variant === 'regular',
      )?.variant || entities.find((x) => x.family === entity.family)?.variant

    return {
      ...entity,
      parentId:
        entity.variant !== defaultVariant ?
          `custom:${entity.family}/${defaultVariant}`
        : undefined,
    }
  })
}

const GOOGLE_FONTS_FAMILY_BLACKLIST = [
  /icons/i,
  /symbols/i,
  /emoji/i,
  /barcode/i,
  /Flow .*/,
  /Redacted/,
  /Linefont/,
  /Wavefont/,
  /Blaka Ink/,
  /Bungee Spice/,
  /Nabla/,
]

const fixProtocol = (url: string) => url.replace('http://', 'https://')

const googleFontsProvider = async (): Promise<FontEntity[]> => {
  if (!process.env.GOOGLE_FONTS_API_KEY) {
    throw new Error('GOOGLE_FONTS_API_KEY not defined')
  }

  const provider = 'google'

  const { items } = await ky
    .get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.GOOGLE_FONTS_API_KEY}&sort=trending`,
    )
    .json<{ items: GoogleFont[] }>()

  return items.flatMap(({ variants, family, menu, files }) => {
    if (GOOGLE_FONTS_FAMILY_BLACKLIST.some((x) => !!family.match(x))) {
      return []
    }

    const defaultVariant =
      variants.includes('regular') ? 'regular' : variants[0]

    return variants.map((variant) => {
      const isRegular = variant === defaultVariant

      return {
        id: `${provider}:${family}/${variant}`,
        url: fixProtocol(files[variant]),
        menuUrl: isRegular ? fixProtocol(menu) : undefined,
        provider,
        family,
        variant,
        variantName: VARIANT_NAMES[variant],
        parentId: !isRegular ? `google:${family}/${defaultVariant}` : undefined,
      }
    })
  })
}

const loadFonts = async (
  vendor: Immutable<VendorRow>,
  fonts: Immutable<FontRow[]>,
  customFonts: Immutable<CustomFontRow[]>,
) => {
  if (state.promise) {
    return state.promise
  }

  const { fonts: fontFeatures } = vendor.features

  state.promise = (async () => {
    const providerPromises: Promise<FontEntity[]>[] = []

    if (fontFeatures.custom) {
      providerPromises.push(customFontsProvider(customFonts))
    }

    if (fontFeatures.google) {
      providerPromises.push(googleFontsProvider())
    }

    const allEntities = (await Promise.all(providerPromises)).flat()

    const fontEntities = fonts.map((font) => {
      const entity = allEntities.find((x) => x.id === font.id)
      if (!entity) {
        throw new Error(`Could not load font '${font.id}'!`)
      }
      return entity
    })

    if (fontFeatures.google === 'all') {
      fontEntities.push(
        ...allEntities.filter((entity) => {
          return (
            entity.provider === 'google' &&
            !fontEntities.find((x) => x.id === entity.id)
          )
        }),
      )
    }

    state.fontEntities = fontEntities

    return state.fontEntities
  })()

  return state.promise
}

export type { FontEntity }
export { loadFonts, parseId }
