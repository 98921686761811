import { useContext } from 'react'
import { AlertMessage } from './Alert'
import RosterTable from './RosterTable'
import TextStyleInput from './TextStyleInput'
import TileSection from './TileSection'
import { AppContext } from '../create-app'

type Props = {
  isColorToneEnabled: boolean
  isOutlineEnabled: boolean
  nodes: any
  setAlert: (alert: AlertMessage) => void
}

const RosterTile = ({
  isColorToneEnabled,
  isOutlineEnabled,
  nodes,
  setAlert,
}: Props) => {
  const {
    vendor: { features },
  } = useContext(AppContext)
  const playerNameColorNode = nodes['players.nameStyle.color']
  const playerNameFontNode = nodes['players.nameStyle.font']
  const playerNameOutline1Node = nodes['players.nameStyle.outline1Color']
  const playerNameOutline2Node = nodes['players.nameStyle.outline2Color']
  const playerNameScaleFactorNode = nodes['players.nameStyle.scaleFactor']

  const playerNumberColorNode = nodes['players.numberStyle.color']
  const playerNumberFontNode = nodes['players.numberStyle.font']
  const playerNumberOutline1Node = nodes['players.numberStyle.outline1Color']
  const playerNumberOutline2Node = nodes['players.numberStyle.outline2Color']
  const playerNumberFrontScaleFactorNode =
    nodes['players.numberStyle.scaleFactor.front']
  const playerNumberBackScaleFactorNode =
    nodes['players.numberStyle.scaleFactor.back']

  return (
    <TileSection section="Players">
      <RosterTable />
      {(playerNameColorNode.isAvailable || playerNameFontNode.isAvailable) && (
        <TextStyleInput
          title="Name Style"
          colorNode={playerNameColorNode}
          fontNode={playerNameFontNode}
          outline1ColorNode={playerNameOutline1Node}
          outline2ColorNode={playerNameOutline2Node}
          scaleFactorNode={playerNameScaleFactorNode}
          setAlert={setAlert}
          isColorToneEnabled={isColorToneEnabled}
          isOutlineEnabled={isOutlineEnabled}
        />
      )}
      {features.playerNumber &&
        (playerNumberColorNode.isAvailable ||
          playerNumberFontNode.isAvailable) && (
          <TextStyleInput
            title="Number Style"
            colorNode={playerNumberColorNode}
            fontNode={playerNumberFontNode}
            outline1ColorNode={playerNumberOutline1Node}
            outline2ColorNode={playerNumberOutline2Node}
            setAlert={setAlert}
            isColorToneEnabled={isColorToneEnabled}
            isOutlineEnabled={isOutlineEnabled}
            playerNumberFrontScaleFactorNode={playerNumberFrontScaleFactorNode}
            playerNumberBackScaleFactorNode={playerNumberBackScaleFactorNode}
          />
        )}
    </TileSection>
  )
}

export default RosterTile
