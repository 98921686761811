import cn from 'classnames'
import { ReactNode } from 'react'
// replace with @orangelv/utils when this is released:
// https://github.com/OrangeLV/blocks/pull/89
import hexToRgb, {
  getPerceivedLightness,
  normalizeRgb,
} from '../../../../customizers/mizuno-apparels/common/hexToRgb'

type Props = {
  color: string
  title?: string
  isSelected?: boolean
  onSelected?: () => void
  hasHoverEffects?: boolean
  children?: ReactNode
  isDashed?: boolean
}

// https://github.com/OrangeLV/Customizer/assets/2584727/f151b8a0-bee2-426e-afc7-ff162735f825
const LIGHTNESS_THRESHOLD = 0.9

const Color = ({
  color,
  title,
  onSelected,
  isSelected,
  hasHoverEffects,
  children,
  isDashed,
}: Props) => (
  <div
    className={cn(
      'rounded-full outline-2 outline-offset-4 outline-[#ccc]',
      isSelected && 'outline',
      hasHoverEffects && 'hover:outline',
      onSelected && 'cursor-pointer',
    )}
  >
    <div
      onClick={onSelected && (() => onSelected())}
      title={title}
      style={{
        backgroundColor: color,
        color:
          (
            getPerceivedLightness(normalizeRgb(hexToRgb(color))) >
            LIGHTNESS_THRESHOLD
          ) ?
            'black'
          : 'white',
      }}
      className={cn(
        'h-[32px] w-[32px] rounded-full outline outline-2 -outline-offset-2',
        isDashed ? 'outline-dashed outline-black/20' : 'outline-black/10',
      )}
    >
      {children}
    </div>
  </div>
)

export default Color
