import { useRef, useState } from 'react'
import cn from 'classnames'
import Icon from './Icon'
import LabelTile from './LabelTile'

export type SelectOption = {
  id: string
  name: string
}

type Props = {
  value: string
  onChange: (value: string | null) => void
  options: SelectOption[]
  label?: string
  iconName?: string
}

const SimpleControl = ({ valueName, isExpanded }) => (
  <>
    <div className="flex items-center">
      <div className="w-full pl-[8px] font-bold">
        {!isExpanded && valueName}
      </div>
    </div>
    <div className="flex items-center gap-[14px]">
      <Icon className="h-full items-center" name="arrowDown" />
    </div>
  </>
)

const LabeledControl = ({ iconName, label, valueName }) => (
  <>
    <div className="flex items-center gap-[8px]">
      {iconName && <Icon className="h-[40px] w-[40px]" name={iconName} />}
      <div className={cn('font-semibold', { 'pl-[8px]': !iconName })}>
        {label}
      </div>
    </div>
    <div className="flex items-center gap-[14px]">
      <div className="font-semibold">{valueName}</div>
      <Icon className="h-full items-center" name="arrowDown" />
    </div>
  </>
)

const SelectTile = ({ value, onChange, options, label, iconName }: Props) => {
  const [isExpanded, setExpanded] = useState(false)
  const [hasShadow, setHasShadow] = useState({ top: false, bottom: false })

  const valueName = options.find(({ id }) => id === value)?.name
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className="relative flex w-full flex-col">
      <div
        className="flex h-simpleTile w-full cursor-pointer items-center justify-between gap-[8px] bg-white pl-[16px] pr-[30px]"
        onClick={() => {
          setExpanded(!isExpanded)
        }}
      >
        {label ?
          <LabeledControl
            valueName={valueName}
            label={label}
            iconName={iconName}
          />
        : <SimpleControl valueName={valueName} isExpanded={isExpanded} />}
      </div>
      <div
        className={cn(
          'mt-[2px] flex w-full flex-grow flex-col',
          isExpanded || 'hidden',
        )}
      >
        <div
          className={cn(
            'pointer-events-none absolute inset-x-0 h-[72px] bg-gradient-to-b from-black/5 to-transparent transition-opacity',
            hasShadow.top ? 'opacity-100' : 'opacity-0',
          )}
        />
        <div
          className="max-h-[476px] overflow-y-auto [&>*:first-child]:mt-0 [&>*:last-child]:mb-0 [&>*]:my-[2px]"
          ref={itemsContainerRef}
          onScroll={(event) => {
            const target = event.target as HTMLDivElement
            const hasShadowNew = {
              top: target.scrollTop > 36,
              bottom:
                target.scrollTop <
                target.scrollHeight - target.clientHeight - 36,
            }
            if (
              hasShadowNew.top !== hasShadow.top ||
              hasShadowNew.bottom !== hasShadow.bottom
            ) {
              setHasShadow(hasShadowNew)
            }
          }}
        >
          {options.map((option) => (
            <LabelTile
              isSelected={value === option.id}
              key={option.id}
              onSelected={() => {
                onChange(option.id)
              }}
            >
              {option.name}
            </LabelTile>
          ))}
        </div>
        <div
          className={cn(
            'pointer-events-none absolute inset-x-0 bottom-0 h-[72px] bg-gradient-to-t from-black/5 to-transparent transition-opacity',
            hasShadow.bottom ? 'opacity-100' : 'opacity-0',
          )}
        />
      </div>
    </div>
  )
}

export default SelectTile
