import cn from 'classnames'
import Icon, { IconName } from './Icon'
import { forwardRef, ReactNode } from 'react'

type Rest =
  | ({ type: 'primary' | 'secondary' | 'danger' } & (
      | { onClick: () => void }
      | { href: string; target?: '_blank' | '_self'; onClick?: () => void }
    ))
  | { type: 'disabled' }

type ButtonWrapperProps = {
  className: string
  children: ReactNode
} & Rest

const ButtonWrapper = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonWrapperProps
>(({ className, children, ...rest }, ref) => {
  if ('onClick' in rest && !('href' in rest)) {
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        onClick={rest.onClick}
        className={className}
      >
        {children}
      </button>
    )
  } else if ('href' in rest) {
    return (
      <a
        ref={ref as React.Ref<HTMLAnchorElement>}
        href={rest.href}
        className={className}
        onClick={rest.onClick}
        target={rest.target ?? '_self'}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={className}
        disabled
      >
        {children}
      </button>
    )
  }
})

ButtonWrapper.displayName = 'ButtonWrapper'

type ButtonProps = {
  icon: IconName
  text: string
} & Rest

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ icon, text, ...rest }, ref) => (
    <ButtonWrapper
      ref={ref}
      className="pointer-events-auto m-[-3px] flex items-center gap-[16px] rounded-[10px] bg-gradient-to-r from-white to-white/70 p-[3px] pr-[20px] text-[18px] font-bold"
      {...rest}
    >
      <Icon
        name={icon}
        className={cn(
          'min-h-[48px] min-w-[48px] rounded-[8px]',
          {
            primary: 'bg-blue text-blueLight',
            secondary: 'bg-blueLight text-blue',
            danger: 'bg-redLight text-red',
            disabled: 'bg-grayLight text-gray',
          }[rest.type],
        )}
      />
      <span
        className={
          {
            primary: 'text-blue',
            secondary: 'text-blue',
            danger: 'text-red',
            disabled: 'text-gray',
          }[rest.type]
        }
      >
        {text}
      </span>
    </ButtonWrapper>
  ),
)

Button.displayName = 'Button'

export default Button
