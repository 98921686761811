import { useState } from 'react'
import Icon from './Icon'

type Props = {
  step?: number
}

const Switcher = ({ step = 1 }: Props) => {
  const [value, setValue] = useState(0)

  const IncrementValue = () => {
    setValue(value + step)
  }
  const DecreaseValue = () => {
    if (value <= 0) setValue(0)
    else setValue(value - step)
  }

  return (
    <div className="flex items-center gap-3">
      <div>{value}%</div>
      <div onClick={DecreaseValue} className="cursor-pointer">
        <Icon name="minusCircle" />
      </div>
      <div onClick={IncrementValue} className="cursor-pointer">
        <Icon name="plusCircle" />
      </div>
    </div>
  )
}

export default Switcher
