import { useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  resetRecipe,
  saveAndCopyLinkToClipboard,
  saveRecipe,
} from '../../../client/common/actions'
import { AlertMessage } from './Alert'
import { AppContext, LoadingContext, RosterContext } from '../create-app'
import { getShareUrl } from '../../../client/share'
import { Popover } from 'react-aria-components'
import { sendToFactory } from '../../../client/factory'
import { unrealRender } from '../../../client/unreal-render'
import { useAppDispatch } from '../../..//client/configureStore'
import Button from './Button'
import getAsset from '../../../getAsset'
import spectacleLogo from '../../assets/powered_by_spectacle_grey.svg'

const Toolbar = ({
  autoRotate,
  setAutoRotate,
  skybox,
  setSkybox,
  setAlert,
  showControls,
}: {
  autoRotate: boolean
  setAutoRotate: (_: boolean) => void
  skybox: undefined | string
  setSkybox: (_: undefined | string) => void
  setAlert: (_: AlertMessage) => void
  showControls: boolean
}) => {
  const dispatch = useAppDispatch()

  const {
    controlTree,
    vendor: { features, vendorName, logoAsset, homeUrl },
  } = useContext(AppContext)
  const { setPlayerBeingPreviewed } = useContext(RosterContext)
  const { setIsLoading } = useContext(LoadingContext)

  const nodes: any = useSelector(controlTree.getNodes)

  const skyboxes = features.skyboxes ?? []

  const [showShareDialog, setShowShareDialog] = useState(false)
  const shareDialogTriggerRef = useRef(null)

  const handleShare = async () => {
    setIsLoading(true)
    await dispatch(saveRecipe(controlTree))
    setIsLoading(false)
    setShowShareDialog(true)
  }

  return (
    <div className="pointer-events-none fixed flex h-full flex-col items-start justify-center gap-[64px] pb-[32px] pl-[32px] pt-[32px]">
      <a className="pointer-events-auto block w-40" href={homeUrl}>
        <img alt={vendorName} src={getAsset(`logo/${logoAsset}`)} />
      </a>
      {showControls && (
        <div className="hidden flex-col items-start gap-[20px] fullUI:flex">
          {nodes['sku'].visibleOptions.length > 1 && (
            <Button
              icon="backBtn"
              text="Back to product selection"
              type="secondary"
              onClick={() => {
                dispatch(resetRecipe(controlTree))
                setPlayerBeingPreviewed(1)
              }}
            />
          )}
          {features.shareDesign && (
            <>
              <Button
                icon="shareBtn"
                text="Share"
                onClick={handleShare}
                type="primary"
                ref={shareDialogTriggerRef}
              />
              <Popover
                triggerRef={shareDialogTriggerRef}
                isOpen={showShareDialog}
                onOpenChange={setShowShareDialog}
                placement="bottom start"
              >
                <div className="flex flex-col gap-[12px] rounded-[10px] bg-white p-[12px] shadow-[0_0_10px_rgba(0,0,0,0.1)]">
                  <Button
                    icon="linkBtn"
                    text="Copy Link"
                    onClick={() => {
                      dispatch(saveAndCopyLinkToClipboard(controlTree))
                      setAlert({ text: 'Link copied!' })
                      setShowShareDialog(false)
                    }}
                    type="secondary"
                  />
                  <Button
                    icon="facebook"
                    text="Facebook"
                    href={getShareUrl('facebook', controlTree)}
                    onClick={() => {
                      setShowShareDialog(false)
                    }}
                    target="_blank"
                    type="secondary"
                  />

                  <Button
                    icon="twitterX"
                    text="Twitter"
                    href={getShareUrl('twitter', controlTree)}
                    onClick={() => {
                      setShowShareDialog(false)
                    }}
                    target="_blank"
                    type="secondary"
                  />
                </div>
              </Popover>
            </>
          )}

          {features.factoryOutput && (
            <Button
              icon="factory"
              text="Send to factory"
              onClick={async () => {
                const recipeId = await dispatch(saveRecipe(controlTree))
                const orderId = Math.floor(Math.random() * 10000).toString()

                const { error } = await sendToFactory(
                  recipeId,
                  orderId,
                  process.env.NODE_ENV === 'development',
                )

                if (error) {
                  console.error(error)
                  setAlert({
                    text: `There was an issue with sending the order to the factory!`,
                  })
                } else {
                  setAlert({
                    text: `Order with ID of "${orderId}" was sent to the factory!`,
                  })
                }
              }}
              type="primary"
            />
          )}
          {features.unrealRender && (
            <Button
              icon="olv"
              text="Vivid render"
              onClick={async () => {
                setAlert({
                  text: 'Vivid render requested...',
                })

                const recipeId = await dispatch(saveRecipe(controlTree))

                const { error } = await unrealRender(recipeId)

                if (error) {
                  setAlert({
                    text: 'There was an error with vivid render!',
                    severity: 'error',
                  })
                } else {
                  setAlert({
                    text: 'Vivid render initiated...',
                  })
                }
              }}
              type="primary"
            />
          )}
          {features.autoRotation && (
            <Button
              icon={autoRotate ? 'pauseBtn' : 'playBtn'}
              text={autoRotate ? 'Stop rotation' : 'Rotate'}
              onClick={() => {
                setAutoRotate(!autoRotate)
              }}
              type="secondary"
            />
          )}
          {skyboxes.length > 0 && (
            <Button
              icon="olv"
              text={skybox ? `${skybox} skybox` : 'No skybox'}
              onClick={() => {
                const currentIndex = skyboxes.findIndex((x) => x === skybox)
                if (currentIndex === skyboxes.length - 1) {
                  setSkybox(undefined)
                } else if (currentIndex === -1) {
                  setSkybox(skyboxes[0])
                } else {
                  setSkybox(skyboxes[currentIndex + 1])
                }
              }}
              type="secondary"
            />
          )}
        </div>
      )}
      <div className="flex-grow" />
      {showControls && (
        <div className="hidden fullUI:block">
          <Button
            icon="resetBtn"
            text="Start over"
            onClick={() => {
              dispatch(resetRecipe(controlTree))
              setPlayerBeingPreviewed(1)
            }}
            type="danger"
          />
        </div>
      )}
      {features.spectacleLogo && (
        <a
          className="pointer-events-auto block"
          href="https://spectaclebuilder.com"
          dangerouslySetInnerHTML={{ __html: spectacleLogo }}
        />
      )}
    </div>
  )
}

export default Toolbar
