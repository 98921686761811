import cn from 'classnames'

import arrowDown from '../../assets/icons/arrowDown.svg'
import arrowUp from '../../assets/icons/arrowUp.svg'
import asterisk from '../../assets/icons/asterisk.svg'
import backBtn from '../../assets/icons/backBtn.svg'
import close from '../../assets/icons/close.svg'
import copy from '../../assets/icons/copy.svg'
import droplet from '../../assets/icons/droplet.svg'
import facebook from '../../assets/icons/facebook.svg'
import factory from '../../assets/icons/factory.svg'
import fontFamily from '../../assets/icons/fontFamily.svg'
import fontStyle from '../../assets/icons/fontStyle.svg'
import image from '../../assets/icons/image.svg'
import info from '../../assets/icons/info.svg'
import letterSpacing from '../../assets/icons/letterSpacing.svg'
import linkBtn from '../../assets/icons/linkBtn.svg'
import magnifyingGlass from '../../assets/icons/magnifyingGlass.svg'
import minusCircle from '../../assets/icons/minusCircle.svg'
import multipleImages from '../../assets/icons/multipleImages.svg'
import olv from '../../assets/icons/olv.svg'
import pauseBtn from '../../assets/icons/pauseBtn.svg'
import playBtn from '../../assets/icons/playBtn.svg'
import plus from '../../assets/icons/plus.svg'
import plusBtn from '../../assets/icons/plusBtn.svg'
import plusCircle from '../../assets/icons/plusCircle.svg'
import resetBtn from '../../assets/icons/resetBtn.svg'
import saveBtn from '../../assets/icons/saveBtn.svg'
import shareBtn from '../../assets/icons/shareBtn.svg'
import spinner from '../../assets/icons/spinner.svg'
import tail from '../../assets/icons/tail.svg'
import text from '../../assets/icons/text.svg'
import textSize from '../../assets/icons/textSize.svg'
import textLayout from '../../assets/icons/textLayout.svg'
import twitterX from '../../assets/icons/twitterX.svg'

const icons = {
  arrowDown,
  arrowUp,
  asterisk,
  backBtn,
  close,
  copy,
  droplet,
  facebook,
  factory,
  fontFamily,
  fontStyle,
  image,
  info,
  letterSpacing,
  linkBtn,
  magnifyingGlass,
  minusCircle,
  multipleImages,
  olv,
  pauseBtn,
  playBtn,
  plus,
  plusBtn,
  plusCircle,
  resetBtn,
  saveBtn,
  shareBtn,
  spinner,
  tail,
  text,
  textSize,
  textLayout,
  twitterX,
}

export type IconName = keyof typeof icons

const Icon = ({ className, name }: { className?: string; name: IconName }) => (
  <div
    className={cn('flex items-center justify-center', className)}
    dangerouslySetInnerHTML={{ __html: icons[name] }}
  />
)

export default Icon
