import { useMemo, useState, useRef, ComponentProps } from 'react'
import cn from 'classnames'
import { ViewportList } from 'react-viewport-list'

import Icon from './Icon'
import Switcher from './Switcher'
import Search from './Search'
import FontPreview from './FontPreview'
import LabelTile from './LabelTile'

type Item = {
  id: string
  url: string
  displayName: string
}

const FontItems = ({
  filteredItems,
  itemId,
  onSelected,
  isOpen,
}: {
  filteredItems: Item[]
  itemId: string | undefined
  onSelected: (item: Item) => void
  isOpen: boolean
}) => {
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const [hasShadow, setHasShadow] = useState({ top: false, bottom: false })

  const initialIndex = filteredItems.findIndex((item) => item.id === itemId)

  if (!isOpen) {
    return null
  }

  return (
    <div className="relative">
      <div
        className={cn(
          'pointer-events-none absolute inset-x-0 h-[72px] bg-gradient-to-b from-black/5 to-transparent transition-opacity',
          hasShadow.top ? 'opacity-100' : 'opacity-0',
        )}
      />
      <div
        className="max-h-[476px] overflow-y-auto [&>*:nth-child(2)]:mt-0 [&>*:nth-last-child(2)]:mb-0 [&>*]:my-[2px]"
        ref={itemsContainerRef}
        onScroll={(event) => {
          // Can't be known statically, but we know where the event comes from
          const target = event.target as HTMLDivElement
          const hasShadowNew = {
            top: target.scrollTop > 36,
            bottom:
              target.scrollTop < target.scrollHeight - target.clientHeight - 36,
          }
          if (
            hasShadowNew.top !== hasShadow.top ||
            hasShadowNew.bottom !== hasShadow.bottom
          ) {
            setHasShadow(hasShadowNew)
          }
        }}
      >
        <ViewportList
          viewportRef={itemsContainerRef}
          items={filteredItems}
          overscan={3} // How many items are rendered outside the viewport.
          initialIndex={initialIndex}
        >
          {(item) => (
            <LabelTile
              key={item.id}
              isSelected={item.id === itemId}
              onSelected={() => {
                onSelected(item)
              }}
            >
              <FontPreview fontUrl={item.url}>{item.displayName}</FontPreview>
            </LabelTile>
          )}
        </ViewportList>
      </div>
      <div
        className={cn(
          'pointer-events-none absolute inset-x-0 bottom-0 h-[72px] bg-gradient-to-t from-black/5 to-transparent transition-opacity',
          hasShadow.bottom ? 'opacity-100' : 'opacity-0',
        )}
      />
    </div>
  )
}

const FontTile = ({
  iconName,
  switcherOn,
  switcherStep,
  title,
  items,
  itemId,
  onSelected,
  isSearchable,
}: {
  iconName: ComponentProps<typeof Icon>['name']
  switcherOn: boolean
  switcherStep?: number
  title: string
  items: Item[]
  itemId: string | undefined
  onSelected: (item: Item) => void
  isSearchable?: boolean
}) => {
  const item = items.find((item) => item.id === itemId)

  const [isOpen, setIsOpen] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const filteredItems = useMemo(() => {
    if (!searchValue) return items
    const regexp = new RegExp(searchValue.toLowerCase(), 'i')
    return items.filter((x) => x.displayName.match(regexp))
  }, [items, searchValue])

  return (
    <div className="w-full">
      <div
        className="flex h-simpleTile w-full cursor-pointer items-center justify-between bg-white pl-[16px] pr-[30px]"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="inline-flex flex-grow items-center gap-[8px]">
          <Icon className="h-[40px] w-[40px]" name={iconName} />
          <div className="font-semibold">{title}</div>
        </div>
        <div
          className={cn('inline-flex items-center gap-[14px]', {
            hidden: switcherOn,
          })}
        >
          {item && (
            <FontPreview fontUrl={item.url}>{item.displayName}</FontPreview>
          )}
          <Icon name="arrowDown" />
        </div>
        <div
          className={cn({
            hidden: !switcherOn,
          })}
        >
          <Switcher step={switcherStep} />
        </div>
      </div>
      <div
        className={cn('mt-[2px]', {
          hidden: !isOpen,
        })}
      >
        <FontItems
          filteredItems={filteredItems}
          itemId={itemId}
          onSelected={onSelected}
          isOpen={isOpen}
        />

        {isSearchable && (
          <Search
            onChange={(ev) => {
              setSearchValue(ev.target.value)
            }}
            value={searchValue}
          />
        )}
      </div>
    </div>
  )
}

export default FontTile
