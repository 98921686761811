import RadioButton from './RadioButton'

type Props = {
  path: string
  title?: string
  isSelected?: boolean
  onSelected?: (isSelected: boolean) => void
}

const ImageTileTriple = (props: Props) => {
  const { path, title, isSelected = false } = props
  const shortTitle = (title || '').split(' ')[0]

  return (
    <div
      onClick={() => {
        if (props.onSelected) {
          props.onSelected(!isSelected)
        }
      }}
      title={title}
      className="relative flex h-imageTileTriple w-imageTileTriple flex-threeInRow cursor-pointer flex-col gap-4 bg-white p-4 font-semibold sm:flex-fourInRow lg:flex-threeInRow"
    >
      <div className="absolute left-0 rounded-br-[20px] bg-white px-4 pb-4">
        <RadioButton isSelected={isSelected} />
      </div>
      <div
        style={{
          backgroundImage: `url(${path})`,
        }}
        className="h-full w-full rounded-[4px] bg-contain"
      />
      {shortTitle}
    </div>
  )
}

export default ImageTileTriple
