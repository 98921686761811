type Props = {
  children: React.ReactNode
  title?: string
}

// 100% in place of 376px doesn't work, unfortunately, as it refers to the
// height for rows... -0.01px is there to account for numerical instability
// (resulting in unwanted wrapping).
const GAP = (376 - 32 * 8) / 7 - 0.01

const ColorSet = (props: Props) => (
  <div>
    <div className="pb-[20px] pl-[24px]">{props.title}</div>
    <div className="flex flex-wrap px-[20px]" style={{ gap: GAP }}>
      {props.children}
    </div>
  </div>
)

export default ColorSet
