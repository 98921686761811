import RadioButton from './RadioButton'

type Props = {
  path: string
  isSelected?: boolean
  onSelected?: (isSelected: boolean) => void
  title?: string
}

const ImageTileDouble = (props: Props) => {
  const { path, title, isSelected = false } = props
  return (
    <div
      onClick={() => {
        if (props.onSelected) {
          props.onSelected(!isSelected)
        }
      }}
      title={title}
      className="relative flex flex-twoInRow cursor-pointer flex-col items-start justify-center bg-white font-semibold sm:flex-threeInRow lg:flex-twoInRow"
    >
      <div className="absolute left-0 top-0 rounded-2xl p-4">
        <RadioButton isSelected={isSelected} />
      </div>

      <div
        style={{
          backgroundImage: `url(${path})`,
        }}
        className="h-imageTileDouble w-imageTileDouble bg-contain"
      />
      <span className="w-full p-4 text-center">{title}</span>
    </div>
  )
}

export default ImageTileDouble

export const EmptyImageTileDouble = () => {
  return (
    <div className="relative flex flex-twoInRow flex-col items-start justify-center bg-white font-semibold sm:flex-threeInRow lg:flex-twoInRow">
      <div className="h-imageTileDouble w-imageTileDouble bg-contain" />
    </div>
  )
}
