import cn from 'classnames'

export type AlertMessage = {
  text: string
  severity?: 'info' | 'error'
  timeout?: number
}

type Props = {
  children: string
  severity?: AlertMessage['severity']
}

const Alert = (props: Props) => {
  const { children, severity } = props

  return (
    <div className="fixed left-1/2 top-[32px] z-30 flex min-h-[64px] min-w-[240px] -translate-x-1/2 animate-fade items-center justify-center gap-2 rounded-2xl bg-white px-6 py-4 shadow-3xl">
      <div
        className={cn(
          'h-3 w-3 rounded-xl',
          !severity || severity === 'info' ? 'bg-info' : 'bg-error',
        )}
      />
      <p>{children}</p>
    </div>
  )
}

export default Alert
