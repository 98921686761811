import Icon from './Icon'

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const Search = (props: Props) => {
  const { onChange, value } = props

  return (
    <div className="relative mt-[2px] h-simpleTile bg-white">
      <Icon
        className="pointer-events-none absolute ml-[16px] h-full"
        name="magnifyingGlass"
      />
      <input
        onChange={onChange}
        type="text"
        value={value}
        placeholder="Search..."
        className="block h-full w-full rounded-lg bg-white pl-[64px] pr-[24px] text-black placeholder-grayLegacy focus:outline-none"
      />
    </div>
  )
}

export default Search
