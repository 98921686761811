import { useEffect, useState } from 'react'

import http from './http'

/**
 * Fetch `url`, but return the previous value while it's being fetched. `url`
 * can be `undefined` because hooks can't be wrapped in conditions.
 */
export function usePreviousWhileFetch<T>(url: string | undefined): {
  data: T | undefined
  isLoading: boolean
} {
  const [state, setState] = useState<{
    data: T | undefined
    isLoading: boolean
  }>({ data: undefined, isLoading: false })

  useEffect(() => {
    if (url === undefined) {
      setState({ data: undefined, isLoading: false })
      return
    }

    setState(({ data }) => ({ data, isLoading: true }))

    const controller = new AbortController()
    http
      .get<T>(url, { signal: controller.signal })
      .then(({ data }) => {
        setState({ data, isLoading: false })
      })
      .catch((e) => {
        if (!controller.signal.aborted) throw e
      })

    return () => controller.abort()
  }, [url])

  return state
}

export function useLocalStorage(key: string, defaultData: any) {
  const dataInLocalStore = localStorage.getItem(key)

  const [dataInState, setDataInState] = useState(
    dataInLocalStore ? JSON.parse(dataInLocalStore) : defaultData,
  )

  const setData = (data: any) => {
    if (data === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(data))
    }
    setDataInState(data)
  }

  return [dataInState, setData]
}

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)

    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches)
    }

    setMatches(mediaQueryList.matches)

    mediaQueryList.addEventListener('change', handleChange)

    return () => {
      mediaQueryList.removeEventListener('change', handleChange)
    }
  }, [query])

  return matches
}
