import cn from 'classnames'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../../../platform/client/configureStore'
import { change } from '../../../../platform/client/control-tree'
import getAsset from '../../../../platform/getAsset'
import { AppContext } from '../create-app'
import { AlertMessage } from './Alert'
import ColorTile from './ColorTile'
import ImageTileDouble, { EmptyImageTileDouble } from './ImageTileDouble'
import ImageTileTriple from './ImageTileTriple'
import ImageUpload from './ImageUpload'
import RosterTile from './RosterTile'
import TextTile from './TextTile'
import TileSection from './TileSection'
import TileSet from './TileSet'
import SelectTile from './SelectTile'

// Why is this needed? To catch the pointer events only around the controls,
// not the section titles (and leave those for the viewer instead).
const Filler = ({ className }: { className: string }) => (
  <div
    className={cn(
      'pointer-events-auto box-content w-sidebar flex-none self-end px-[32px]',
      className,
    )}
  />
)

const Sidebar = ({ setAlert }: { setAlert: (_: AlertMessage) => void }) => {
  const {
    controlTree,
    vendor: { features },
  } = useContext(AppContext)
  const nodes: any = useSelector(controlTree.getNodes)

  const dispatch = useAppDispatch()

  const fabricNode = nodes['fabric']
  const fabricOptions =
    fabricNode.isAvailable ?
      fabricNode.visibleOptions || fabricNode.options
    : []

  const designNode = nodes['design.design']
  const designOptions =
    designNode.isAvailable ?
      [{ id: null, name: 'None' }].concat(
        designNode.visibleOptions || designNode.options,
      )
    : []

  const designColor1Node = nodes['design.color1']
  const designColor2Node = nodes['design.color2']
  const designColor3Node = nodes['design.color3']
  const designColor4Node = nodes['design.color4']

  const designFileNode = nodes['design.file']

  const brandLogoColorNode = nodes['brandLogoColor']

  const productId = nodes['sku'].value
  const isRosterEnabled = nodes['sku'].object.isRosterEnabled
  const productColorAreas = nodes['sku'].object.colorAreas
  const productColorNodes = productColorAreas.map(
    (area) => nodes[`${area}Color`],
  )
  const isSomeProductColorAvailable = productColorNodes.some(
    (product) => product.isAvailable,
  )

  const teamNameTextNode = nodes['teamName.text']

  const teamLogoNode = nodes['teamLogo']
  const teamLogoNodeValue = teamLogoNode.value
  const teamLogoImageUrl =
    teamLogoNodeValue ? `/api/images/${teamLogoNodeValue.id}` : undefined

  const playerSize = nodes['players.roster.1.size']

  return (
    <div className="pointer-events-none hidden h-full flex-col text-lg drop-shadow-[0_0_64px_#E6E6E6] fullUI:flex">
      <Filler className="h-[32px]" />

      {fabricNode.isAvailable && (
        <TileSection section="Fabric">
          <TileSet>
            {fabricOptions.map((fabric) => (
              <ImageTileTriple
                key={fabric.id}
                path={getAsset(`icons/fabric/${fabric.id}.png`)}
                title={fabric.name}
                isSelected={fabricNode.value === fabric.id}
                onSelected={() => {
                  dispatch(change(fabricNode.keyPath, fabric.id))
                }}
              />
            ))}
          </TileSet>
        </TileSection>
      )}

      {designNode.isAvailable && designOptions.length > 1 && (
        <TileSection section="Design">
          <TileSet>
            {designOptions.map((design) => (
              <ImageTileDouble
                key={design.id}
                isSelected={nodes['design.design'].value === design.id}
                onSelected={() => {
                  dispatch(change(designNode.keyPath, design.id))
                }}
                title={design.name}
                path={getAsset(
                  `thumbnails/${productId}-${design.id ?? 'none'}.png`,
                )}
              />
            ))}
            {designOptions.length % 2 ?
              <EmptyImageTileDouble />
            : null}
          </TileSet>

          {(designColor1Node?.isAvailable ||
            designColor2Node?.isAvailable ||
            designColor3Node?.isAvailable ||
            designColor4Node?.isAvailable) && (
            <TileSet>
              {designColor1Node?.isAvailable && (
                <ColorTile
                  label="Color 1"
                  colors={designColor1Node.options}
                  color={designColor1Node.object}
                  onSelected={(color) => {
                    dispatch(
                      change(designColor1Node.keyPath, color ? color.id : null),
                    )
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              )}

              {designColor2Node?.isAvailable && (
                <ColorTile
                  label="Color 2"
                  colors={designColor2Node.options}
                  color={designColor2Node.object}
                  onSelected={(color) => {
                    dispatch(
                      change(designColor2Node.keyPath, color ? color.id : null),
                    )
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              )}

              {designColor3Node?.isAvailable && (
                <ColorTile
                  label="Color 3"
                  colors={designColor3Node.options}
                  color={designColor3Node.object}
                  onSelected={(color) => {
                    dispatch(
                      change(designColor3Node.keyPath, color ? color.id : null),
                    )
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              )}

              {designColor4Node?.isAvailable && (
                <ColorTile
                  label="Color 4"
                  colors={designColor4Node.options}
                  color={designColor4Node.object}
                  onSelected={(color) => {
                    dispatch(
                      change(designColor4Node.keyPath, color ? color.id : null),
                    )
                  }}
                  setAlert={setAlert}
                  isColorToneEnabled={features.colorTone}
                />
              )}
            </TileSet>
          )}
        </TileSection>
      )}

      {designFileNode.isAvailable && (
        <TileSection section="Full Custom">
          <TileSet>
            <ImageUpload
              imageUrl={
                designFileNode.value ?
                  `/api/images/${designFileNode.value.id}`
                : undefined
              }
              accept="image/svg+xml"
              onChanged={(file) => {
                dispatch(change(designFileNode.keyPath, file))
              }}
              onRemoved={() => {
                dispatch(change(designFileNode.keyPath, null))
              }}
              setAlert={setAlert}
              kindOfFile="your design"
            />

            <div className="w-sidebar rounded-b-[12px] bg-white px-[16px] py-[12px] text-sm">
              Download the template (
              <a
                className="text-orange hover:underline"
                href={getAsset(
                  `full-custom-templates/${productId}-template.svg`,
                )}
                download={`${productId}-template.svg`}
                target="_blank"
                rel="noreferrer"
              >
                {productId}-template.svg
              </a>
              ), open it in a vector graphics editor like Adobe Illustrator or{' '}
              <a
                className="text-orange hover:underline"
                href="https://inkscape.org/"
                target="_blank"
                rel="noreferrer"
              >
                Inkscape
              </a>{' '}
              (free) and follow the instructions inside the file.
            </div>
          </TileSet>
        </TileSection>
      )}

      {(brandLogoColorNode.isAvailable || isSomeProductColorAvailable) && (
        <TileSection section="Colors">
          {brandLogoColorNode.isAvailable && (
            <TileSet>
              <ColorTile
                label="Logo"
                colors={brandLogoColorNode.options}
                color={brandLogoColorNode.object}
                onSelected={(color) => {
                  dispatch(
                    change(brandLogoColorNode.keyPath, color ? color.id : null),
                  )
                }}
                setAlert={setAlert}
                isColorToneEnabled={features.colorTone}
              />
            </TileSet>
          )}

          {isSomeProductColorAvailable && (
            <TileSet>
              {productColorNodes.map((colorNode, i) =>
                colorNode.isAvailable ?
                  <ColorTile
                    key={colorNode.keyPath}
                    label={productColorAreas[i]}
                    colors={colorNode.options}
                    color={colorNode.object}
                    onSelected={(color) => {
                      dispatch(
                        change(colorNode.keyPath, color ? color.id : null),
                      )
                    }}
                    setAlert={setAlert}
                    isColorToneEnabled={features.colorTone}
                  />
                : null,
              )}
            </TileSet>
          )}
        </TileSection>
      )}

      {teamNameTextNode.isAvailable && (
        <TextTile
          title="Team Name"
          textNode={nodes['teamName.text']}
          colorNode={nodes['teamName.color']}
          fontNode={nodes['teamName.font']}
          outline1ColorNode={nodes['teamName.outline1Color']}
          outline2ColorNode={nodes['teamName.outline2Color']}
          scaleFactorNode={nodes['teamName.scaleFactor']}
          setAlert={setAlert}
          isColorToneEnabled={features.colorTone}
          isOutlineEnabled={features.outline}
        />
      )}

      {isRosterEnabled && (
        <RosterTile
          nodes={nodes}
          setAlert={setAlert}
          isColorToneEnabled={features.colorTone}
          isOutlineEnabled={features.outline}
        />
      )}

      {!isRosterEnabled &&
        features.playerName &&
        nodes['players.roster.1.name']?.isAvailable && (
          <TextTile
            title="Player Name"
            textNode={nodes['players.roster.1.name']}
            colorNode={nodes['players.nameStyle.color']}
            fontNode={nodes['players.nameStyle.font']}
            outline1ColorNode={nodes['players.nameStyle.outline1Color']}
            outline2ColorNode={nodes['players.nameStyle.outline2Color']}
            scaleFactorNode={nodes['players.nameStyle.scaleFactor']}
            setAlert={setAlert}
            isColorToneEnabled={features.colorTone}
            isOutlineEnabled={features.outline}
          />
        )}

      {!isRosterEnabled &&
        features.playerNumber &&
        nodes['players.roster.1.number']?.isAvailable && (
          <TextTile
            title="Player Number"
            textNode={nodes['players.roster.1.number']}
            colorNode={nodes['players.numberStyle.color']}
            fontNode={nodes['players.numberStyle.font']}
            outline1ColorNode={nodes['players.numberStyle.outline1Color']}
            outline2ColorNode={nodes['players.numberStyle.outline2Color']}
            playerNumberFrontScaleFactorNode={
              nodes['players.numberStyle.scaleFactor.front']
            }
            playerNumberBackScaleFactorNode={
              nodes['players.numberStyle.scaleFactor.back']
            }
            setAlert={setAlert}
            isColorToneEnabled={features.colorTone}
            isOutlineEnabled={features.outline}
          />
        )}

      {teamLogoNode.isAvailable && (
        <TileSection section="Team Logo">
          <TileSet>
            <ImageUpload
              imageUrl={teamLogoImageUrl}
              accept="image/svg+xml"
              onChanged={(file) => {
                dispatch(change(teamLogoNode.keyPath, file))
              }}
              onRemoved={() => {
                dispatch(change(teamLogoNode.keyPath, null))
              }}
              setAlert={setAlert}
              kindOfFile="SVG image"
            />
          </TileSet>
        </TileSection>
      )}

      {!isRosterEnabled && playerSize.visibleOptions.length > 1 && (
        <TileSection section="Size">
          <TileSet>
            <SelectTile
              options={playerSize.visibleOptions}
              value={playerSize.value}
              onChange={(value) => {
                dispatch(change(playerSize.keyPath, value))
              }}
            />
          </TileSet>
        </TileSection>
      )}

      <Filler className="h-[120px]" />
    </div>
  )
}

export default Sidebar
