import { useState } from 'react'
import cn from 'classnames'

import { stringToSvg } from '@orangelv/utils-svg'

import { AxiosResponse } from 'axios'
import http from '../../../../platform/client/http'

import { AlertMessage } from './Alert'
import Icon from './Icon'
import Spinner from './Spinner'

const isSvgValid = (file: File) =>
  new Promise((resolve) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      const svgString = reader.result as string

      try {
        stringToSvg(svgString, file.name)
      } catch (e) {
        resolve(false)
        return
      }

      resolve(true)
    })

    reader.readAsText(file)
  })

const ImageUpload = ({
  imageUrl,
  onChanged,
  onRemoved,
  setAlert,
  accept,
  kindOfFile,
}: {
  imageUrl: string | undefined
  onChanged: (file: { id: string; filename: string }) => void
  onRemoved: () => void
  setAlert: (alert: AlertMessage) => void
  accept?: string
  kindOfFile: string
}) => {
  const [isUploading, setUploading] = useState(false)

  const uploadImage = async (file: File) => {
    setUploading(true)

    if (!(await isSvgValid(file))) {
      setUploading(false)
      setAlert({ text: 'Uploaded SVG is not valid!', severity: 'error' })
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    let responseData: { id: string } | undefined

    try {
      const response = await http.post<FormData, AxiosResponse<{ id: string }>>(
        `/api/images`,
        formData,
      )
      responseData = response.data
    } catch (e) {
      // Pass
    }

    setUploading(false)

    if (!responseData) {
      setAlert({ text: 'Upload failed, try again!', severity: 'error' })
    }

    return responseData?.id
  }

  return (
    <div className="relative w-full bg-white p-4">
      <label
        className={cn(
          'flex h-44 w-full cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed border-grayLegacy',
          imageUrl && 'hidden',
        )}
        onDrop={async (ev) => {
          ev.preventDefault()

          const file = ev.dataTransfer.files[0]

          if (file.type !== accept) {
            setAlert({ text: 'Incorrect image file type!', severity: 'error' })

            return
          }

          const id = await uploadImage(file)

          if (!id) {
            return
          }

          onChanged({ id, filename: file.name })
        }}
        onDragOver={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
        }}
        onDragEnter={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
        }}
        onDragLeave={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
        }}
      >
        <div className="flex items-center justify-center gap-4">
          {isUploading ?
            <Spinner />
          : <>
              <Icon name="multipleImages" />
              <div className="text-base font-semibold">
                Drag {kindOfFile} here
                <br />
                or&nbsp;
                <span className="text-orange hover:underline">upload it</span>
              </div>
            </>
          }
        </div>
        <input
          type="file"
          className="hidden"
          accept={accept ?? undefined}
          onChange={async (ev) => {
            const file = ev.target.files?.[0]
            if (!file) {
              return
            }

            const id = await uploadImage(file)

            if (!id) {
              return
            }

            onChanged({ id, filename: file.name })
          }}
        />
      </label>
      <div
        className={cn(
          'relative flex h-44 w-full items-center justify-center rounded-md bg-black/5',
          imageUrl || 'hidden',
        )}
      >
        <div
          onClick={() => {
            onRemoved()
          }}
          className="absolute right-0 top-0 z-10 cursor-pointer rounded-bl-3xl bg-white pb-4 pl-4"
        >
          <Icon name="close" />
        </div>
        <div
          style={
            imageUrl ?
              {
                backgroundImage: `url(${imageUrl})`,
              }
            : undefined
          }
          className="h-full w-full bg-contain bg-center bg-no-repeat"
        />
      </div>
    </div>
  )
}

export default ImageUpload
