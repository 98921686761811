// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignPartRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "designId": "vermelho",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/highway_vermelho",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_vermelho",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-200.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_vermelho_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_vermelho_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_backStripe",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-150.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "vermelho",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/highway_verde",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_verde",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-200.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_verde_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_verde_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_backStripe",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-150.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "verde",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/highway_azul",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_azul",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-200.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_azul_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_azul_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_backStripe",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-150.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "azul",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/highway_amarelo",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_amarelo",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-200.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_amarelo_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_amarelo_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/highway_backStripe",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-150.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_left",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "amarelo",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/highway_sleeveStripe_right",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "fog",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/fog_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "fog",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/fog_front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-200.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "fog",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/fog_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "fog",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/fog_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "fog",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/fog_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-150.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "nova",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/nova_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-450.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "nova",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/nova_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateX": "0.00",
    "translateY": "-100.00",
    "spreadX": "0.00",
    "scale": "1.40"
  },
  {
    "designId": "nova",
    "pieceNameGlob": "sleeve_left",
    "productIdGlob": "*",
    "assetName": "designs/nova_sleeveLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  },
  {
    "designId": "nova",
    "pieceNameGlob": "sleeve_right",
    "productIdGlob": "*",
    "assetName": "designs/nova_sleeveRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "middleTop",
    "translateY": "-60.00",
    "scale": "1.75"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignPartRow[]>> = data;

export type DesignPart = typeof typed[number];

export { typed as DESIGN_PARTS };
