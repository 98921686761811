import cn from 'classnames'
import { useState, useMemo } from 'react'
import clipboard from 'clipboard-js'

import { AlertMessage } from './Alert'
import ColorSet from './ColorSet'
import Color from './Color'
import ToneSlider from './ToneSlider'
import Icon from './Icon'

type Props<C> = {
  label: string
  colors: C[]
  color: C | null
  isOptional?: boolean
  onSelected: (color: C | null) => void
  setAlert: (alert: AlertMessage) => void
  isColorToneEnabled: boolean
}

export function ColorTile<
  C extends { id: string; hex: string; parentId: string; name: string },
>(props: Props<C>) {
  const {
    label,
    colors,
    color,
    isOptional,
    onSelected,
    setAlert,
    isColorToneEnabled,
  } = props

  const [isVisible, setVisible] = useState(false)

  const tones = useMemo(
    () =>
      color === null ?
        []
      : colors.filter((x) =>
          color.parentId ?
            x.parentId === color.parentId || x.id === color.parentId
          : x.parentId === color.id || x.id === color.id,
        ),
    [colors, color],
  )

  return (
    // TODO: Set click event on all closed tile
    <div className="flex w-full flex-col bg-white">
      <div
        className="flex h-simpleTile w-full cursor-pointer items-center justify-between pl-[24px] pr-[20px]"
        onClick={() => {
          setVisible(!isVisible)
        }}
      >
        <div className="flex items-center gap-[16px]">
          <Icon className="h-[24px] w-[24px]" name="droplet" />
          <div className="font-semibold capitalize">{label}</div>
        </div>

        <div className="flex items-center gap-[12px]">
          {color && (
            <div
              className="flex h-[32px] cursor-pointer items-center gap-[8px] rounded-[8px] bg-lighterGrey pl-[14px] pr-[12px] font-mono leading-none"
              onClick={(e) => {
                e.stopPropagation()
                if (color === null) {
                  return
                }

                clipboard.copy(color.hex)
                setAlert({ text: 'Color copied!' })
              }}
            >
              <div>{color.hex}</div>
              <Icon name="copy" />
            </div>
          )}
          <Color color={color?.hex ?? '#ffffff'} isDashed={!color}>
            <Icon className="h-full" name="arrowDown" />
          </Color>
        </div>
      </div>

      <div
        className={cn(
          'flex w-full flex-grow flex-col pb-[20px]',
          isVisible || 'hidden',
        )}
      >
        <ColorSet title="Palette">
          {isOptional && (
            <Color
              title="disabled"
              isSelected={!color}
              onSelected={() => {
                onSelected(null)
              }}
              color="#ffffff"
              hasHoverEffects
              isDashed
            />
          )}
          {colors
            .filter((x) => x.parentId === undefined)
            .map((x) => (
              <Color
                key={x.id}
                title={x.name}
                isSelected={
                  !!color && (x.id === color.id || x.id === color.parentId)
                }
                onSelected={() => {
                  onSelected(x)
                }}
                color={x.hex}
                hasHoverEffects
              />
            ))}
        </ColorSet>

        {isColorToneEnabled && color && tones.length && (
          <>
            <div className="mb-[20px] mt-[28px] pl-[24px]">Tone</div>
            <ToneSlider
              colors={tones}
              color={color}
              onSelected={(color) => {
                onSelected(color)
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ColorTile
