import { useContext, useEffect, useState, useMemo } from 'react'

import { AppContext } from './create-app'
import { loadFonts, parseId } from '../common/fonts'
import type { FontEntity } from '../common/fonts'

const useFonts = (fontId: string | undefined) => {
  const { vendor, fonts, customFonts } = useContext(AppContext)

  const [fontEntities, setFontEntities] = useState<FontEntity[]>([])

  useEffect(() => {
    ;(async () => {
      const fontEntities = await loadFonts(vendor, fonts, customFonts)
      setFontEntities(fontEntities)
    })()
  }, [vendor, fonts, customFonts])

  const { provider, family } = useMemo(() => {
    if (fontId) {
      return parseId(fontId)
    }
    return { provider: undefined, family: undefined }
  }, [fontId])

  const fontFamilyItems = useMemo(
    () =>
      fontEntities
        .filter((x) => !x.parentId)
        .map((fontEntity) => ({
          id: fontEntity.id,
          url: fontEntity.menuUrl ?? fontEntity.url,
          displayName: fontEntity.family,
        })),
    [fontEntities],
  )

  const fontFamilyItemId = useMemo(
    () =>
      fontEntities.find(
        (x) => x.provider === provider && x.family === family && !x.parentId,
      )?.id,
    [fontEntities, provider, family],
  )

  const fontVariantItems = useMemo(
    () =>
      fontEntities
        .filter((x) => x.family === family)
        .map((fontEntity) => ({
          id: fontEntity.id,
          url: fontEntity.url,
          displayName: fontEntity.variantName,
        })),
    [fontEntities, family],
  )

  // The ID of variant item is just `fontId` because ID consists of provider + family + variant.
  // No need to memo this.
  const fontVariantItemId = fontId

  return {
    fontEntities,
    fontFamilyItems,
    fontFamilyItemId,
    fontVariantItems,
    fontVariantItemId,
  }
}

export { useFonts }
