import { ReactNode } from 'react'
import RadioButton from './RadioButton'

type Props = {
  children?: ReactNode
  isSelected?: boolean
  onSelected?: (isSelected: boolean) => void
}

const LabelTile = (props: Props) => {
  const { children } = props
  const isSelected = props.isSelected ?? false

  return (
    <div
      onClick={() => {
        if (props.onSelected) {
          props.onSelected(!isSelected)
        }
      }}
      className="flex h-simpleTile w-full cursor-pointer items-center bg-white pr-4"
    >
      <div className="relative pl-[24px] pr-[16px]">
        <RadioButton isSelected={isSelected} />
      </div>
      <div className="font-semibold">{children}</div>
    </div>
  )
}

export default LabelTile
