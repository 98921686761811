import { useCallback } from 'react'
import getAsset from '../../../getAsset'
import Papa from 'papaparse'

type Props = {
  setRosterValues: (values: string[][]) => void
}

const EXAMPLE_FILENAME = 'team-roster.xlsx'

const RosterUpload = (props: Props) => {
  const { setRosterValues } = props

  const handleFileUpload = useCallback(
    (event) => {
      event.preventDefault()

      const input = document.createElement('input')
      input.accept = '.csv'
      input.type = 'file'

      input.addEventListener('change', (event) => {
        const target = event.target as HTMLInputElement
        const file = target.files?.item(0)

        if (!file) {
          return
        }

        Papa.parse(file, {
          skipEmptyLines: 'greedy',
          complete: ({ data }) => {
            setRosterValues(data as string[][])
          },
        })
      })

      input.click()
    },
    [setRosterValues],
  )

  return (
    <div className="w-sidebar rounded-b-[12px] bg-white px-[16px] py-[12px] text-sm">
      <a
        href="#"
        onClick={handleFileUpload}
        className="text-orange hover:underline"
      >
        Upload CSV
      </a>{' '}
      with team roster to autofill the form.
      <br />
      Download example Excel spreadsheet (
      <a
        href={getAsset(EXAMPLE_FILENAME)}
        download={EXAMPLE_FILENAME}
        target="_blank"
        rel="noreferrer"
        className="text-orange hover:underline"
      >
        {EXAMPLE_FILENAME}
      </a>
      ) that you can edit and export as CSV.
    </div>
  )
}

export default RosterUpload
