import cn from 'classnames'

import Icon from './Icon'

type Props = {
  value: string
  onChanged: (value: string) => void
}

const TextInput = (props: Props) => {
  const { value, onChanged } = props

  return (
    <div className="flex h-simpleTile w-full items-center justify-between bg-white px-[24px]">
      <input
        onChange={(ev) => {
          onChanged(ev.target.value)
        }}
        type="text"
        value={value}
        placeholder="Enter text here"
        className="block w-full rounded-lg bg-white p-2 font-normal text-black placeholder-grayLegacy focus:outline-none"
      />
      <div className={cn(!!value && 'hidden')}>
        <Icon name="asterisk" />
      </div>

      <div
        onClick={() => {
          onChanged('')
        }}
        className={cn('cursor-pointer', !!value || 'hidden')}
      >
        <Icon name="close" />
      </div>
    </div>
  )
}

export default TextInput
