import { Immutable } from '@orangelv/utils'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { loadRecipe, setOriginValues } from '../../client/common/actions'
import { isSkuSelector, matchesSelector } from '../../client/common/selectors'
import configureRouter from '../../client/configureRouter'
import configureStore from '../../client/configureStore'
import { createControlTree } from '../../client/control-tree'
import { createUtils } from '../../client/control-tree/debugUtils'
import FileUpload from '../../client/control-tree/fileUpload'
import Repeater from '../../client/control-tree/repeater'
import Select from '../../client/control-tree/select'
import Text from '../../client/control-tree/text'
import { loadFonts } from '../common/fonts'
import { SelectOption } from './components/SelectTile'
import {
  ColorRow,
  CustomFontRow,
  DesignPartRow,
  DesignRow,
  FabricRow,
  FillRow,
  FontRow,
  PlacementRow,
  ProductRow,
  TextDecoration,
  VendorRow,
} from '../common/types'
import App from './components/App'
import generateFullCustomTemplates from './generate-full-custom-templates'

import './index.css'

const getPlacementScaleFactorOptions = (
  placement: Immutable<PlacementRow>,
): SelectOption[] => {
  if (!placement.scaleFactors || !placement.scaleFactorNames) return []

  const scaleFactorNames = placement.scaleFactorNames

  return placement.scaleFactors.map((scaleFactor, i) => ({
    name: scaleFactorNames[i],
    id: scaleFactor,
  }))
}

const getProductPlacementRow = (
  placements: Immutable<PlacementRow[]>,
  productId: string,
  decoration: string,
  pieceName?: string,
): Immutable<PlacementRow> | undefined =>
  placements.find(
    (placement) =>
      placement.decoration === decoration &&
      placement.productId === productId &&
      (pieceName === undefined || placement.pieceName === pieceName),
  )

const createScaleFactorNode = (
  placements: Immutable<PlacementRow[]>,
  features: Immutable<VendorRow>['features'],
  decoration: string,
  pieceName?: string | undefined,
) => {
  return Select({
    dependencies: ['sku'],
    isAvailable: (sku) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      return !!(
        features.textSize &&
        placement?.scaleFactors &&
        placement.scaleFactors.length > 0
      )
    },
    options: (sku) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      return placement ? getPlacementScaleFactorOptions(placement) : []
    },
    defaultValue: (sku) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      if (!placement) return null

      const options = getPlacementScaleFactorOptions(placement)

      if (options.length === 0) return null

      return options[options.length - 1].id
    },
  })
}

const createTextPlacement = (
  { features, defaults }: Immutable<VendorRow>,
  name: TextDecoration,
  colors: Immutable<ColorRow[]>,
  placements: Immutable<PlacementRow[]>,
) => ({
  text: Text({
    isAvailable: () => features[name],
    defaultValue: defaults?.[name]?.text,
  }),

  font: Text({
    dependencies: [`${name}.text`],
    defaultValue: defaults?.[name]?.font,
  }),

  scaleFactor: createScaleFactorNode(placements, features, name),

  color: Select({
    options: colors,
    defaultValue: defaults?.[name]?.colorId,
  }),

  outline1Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline1ColorId ?? null,
  }),

  outline2Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline2ColorId ?? null,
  }),
})

/*
 * Creates color nodes (e.g. sleevesColor) from the product colorAreas defined
 * in sheets
 */
const createProductColors = (colors, enabledProducts, features) => {
  const colorAreas = Array.from(
    new Set(enabledProducts.flatMap((product) => product.colorAreas)),
  )

  return Object.fromEntries(
    colorAreas.map((area) => {
      return [
        `${area}Color`,
        Select({
          dependencies: ['sku'],
          options: colors,
          isAvailable: (sku) =>
            features.productColor && sku.colorAreas.includes(area),
        }),
      ]
    }),
  )
}

const createPlayersTextStyle = (
  name,
  colors,
  features,
  defaults,
  placements,
) => ({
  font: Text({
    defaultValue: defaults?.[name]?.font,
  }),

  color: Select({
    options: colors,
    defaultValue: defaults?.[name]?.colorId,
  }),

  scaleFactor: createScaleFactorNode(placements, features, name),

  outline1Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline1ColorId ?? null,
  }),

  outline2Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline2ColorId ?? null,
  }),
})

const createPlayers = (colors, features, defaults, placements) => {
  return {
    nameStyle: createPlayersTextStyle(
      'playerName',
      colors,
      features,
      defaults,
      placements,
    ),
    numberStyle: {
      font: Text({
        defaultValue: defaults?.playerNumber?.font,
      }),

      color: Select({
        options: colors,
        defaultValue: defaults?.playerNumber?.colorId,
      }),

      scaleFactor: {
        front: createScaleFactorNode(
          placements,
          features,
          'playerNumber',
          'front',
        ),
        back: createScaleFactorNode(
          placements,
          features,
          'playerNumber',
          'back',
        ),
      },

      outline1Color: Select({
        isAvailable: () => features.outline,
        options: colors,
        isRequired: false,
        defaultValue: defaults?.playerNumber?.outline1ColorId ?? null,
      }),

      outline2Color: Select({
        isAvailable: () => features.outline,
        options: colors,
        isRequired: false,
        defaultValue: defaults?.playerNumber?.outline2ColorId ?? null,
      }),
    },
    roster: Repeater({
      defaultRepeats: 1,
      controls: {
        name: Text({
          dependencies: ['sku'],
          isAvailable: (product: ProductRow) =>
            placements.some(
              (placement) =>
                placement.decoration === 'playerName' &&
                placement.productId === product.id,
            ),
          defaultValue: defaults?.playerName.text,
        }),
        number: Text({
          dependencies: ['sku'],
          isAvailable: (product: ProductRow) =>
            placements.some(
              (placement) =>
                placement.decoration === 'playerNumber' &&
                placement.productId === product.id,
            ),
          defaultValue: defaults?.playerNumber.text,
        }),
        size: Select({
          dependencies: ['sku'],
          // not sure why we need "options" according to TS types
          options: [],
          visibleOptions: (product: ProductRow) =>
            (product.sizes ?? []).map((size) => ({ id: size, name: size })),
          defaultValue: (product: ProductRow) => product.defaultSize,
        }),
      },
    }),
  }
}

export const AppContext = createContext<
  Immutable<{
    patternPackage: string
    vendor: VendorRow
    products: ProductRow[]
    designs: DesignRow[]
    controlTree: any
    designParts: DesignPartRow[]
    colorDict: Record<string, ColorRow>
    fonts: FontRow[]
    customFonts: CustomFontRow[]
    placements: PlacementRow[]
  }>
>(null as any) // https://stackoverflow.com/a/67957976/242684

export const LoadingContext = createContext<{
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}>({
  isLoading: true,
  setIsLoading: () => {},
})

export const RosterContext = createContext<{
  playerBeingPreviewed: number
  setPlayerBeingPreviewed: Dispatch<SetStateAction<number>>
}>({
  playerBeingPreviewed: 1,
  setPlayerBeingPreviewed: () => {},
})

const AppContextProvider = ({
  children,
  initialValues,
}: PropsWithChildren<{ initialValues: any }>): JSX.Element => {
  const value = useMemo(() => ({ ...initialValues }), [initialValues])
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const LoadingContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading])
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

const RosterContextProvider: React.FC<{
  children: React.ReactNode
  initialPlayerBeingPreviewed?: number
}> = ({ children, initialPlayerBeingPreviewed = 1 }) => {
  const [playerBeingPreviewed, setPlayerBeingPreviewed] = useState(
    initialPlayerBeingPreviewed,
  )
  const value = useMemo(
    () => ({ playerBeingPreviewed, setPlayerBeingPreviewed }),
    [playerBeingPreviewed],
  )
  return (
    <RosterContext.Provider value={value}>{children}</RosterContext.Provider>
  )
}

export function createApp({
  patternPackage,
  vendors,
  products,
  fabrics,
  designs,
  designParts,
  colors,
  colorDict,
  fills,
  fonts,
  customFonts,
  placements,
}: Immutable<{
  patternPackage: string
  vendors: VendorRow[]
  products: ProductRow[]
  fabrics: FabricRow[]
  designs: DesignRow[]
  designParts: DesignPartRow[]
  colors: ColorRow[]
  colorDict: Record<string, ColorRow>
  fills: FillRow[]
  fonts: FontRow[]
  customFonts: CustomFontRow[]
  placements: PlacementRow[]
}>) {
  const vendor = vendors.find(
    (x) => x.hostname === window.serverConfig?.hostname,
  )
  if (!vendor) throw new Error('Bad or missing window.serverConfig.hostname')

  const { features, defaults } = vendor

  // Kick off font loading asap!
  loadFonts(vendor, fonts, customFonts)

  const enabledProducts = products.filter((x) => x.isEnabled)

  const controlTree = createControlTree({
    sku: Select({
      options: products,
      visibleOptions: enabledProducts,
      defaultValue: enabledProducts[0].id,
    }),

    fabric: Select({
      dependencies: ['sku'],
      options: fabrics,
      isAvailable: () => features.fabric,
      visibleOptions: (product: ProductRow) =>
        product.fabrics?.map((x) => fabrics.find((y) => x === y.id)),
    }),

    design: {
      design: Select({
        dependencies: ['sku'],
        isRequired: false,
        isAvailable: () => features.design,
        options: designs,
        visibleOptions: (product: ProductRow) =>
          designs.filter(
            (x) =>
              x.limitations.productIds.includes(product.id) &&
              (x.id !== 'fullCustom' || features.fullCustom),
          ),
      }),

      color1: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 1,
        defaultValue: defaults?.designColor1Id,
      }),

      color2: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 2,
        defaultValue: defaults?.designColor2Id,
      }),

      color3: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 3,
        defaultValue: defaults?.designColor3Id,
      }),

      color4: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 4,
        defaultValue: defaults?.designColor4Id,
      }),

      file: FileUpload({
        dependencies: ['design.design'],
        isAvailable: (design: DesignRow | null) => design?.id === 'fullCustom',
        isRequired: false,
      }),
    },

    fill: Select({
      options: fills,
      isAvailable: () => features.fill,
    }),

    brandLogoColor: Select({
      options: colors,
      isAvailable: () => features.brandLogo,
      defaultValue: defaults?.brandLogoColorId,
    }),

    ...createProductColors(colors, enabledProducts, features),

    teamName: createTextPlacement(vendor, 'teamName', colors, placements),

    players: createPlayers(colors, features, defaults, placements),

    teamLogo: FileUpload({
      dependencies: ['sku'],
      isAvailable: (product: ProductRow) =>
        features.teamLogo &&
        placements.find((placement) => placement.decoration === 'teamLogo')
          ?.productId === product.id,
    }),
  })

  const store = configureStore({ updater: controlTree.updater, controlTree })

  for (const [name, fn] of Object.entries(createUtils(store, controlTree))) {
    window[name] = fn
  }

  window['generateFullCustomTemplates'] = generateFullCustomTemplates

  const routes = [
    '/sku/:sku',
    '/design/:recipeId(/:status)',
    '/review/:recipeId',
  ]

  const { initialMatches } = configureRouter(controlTree, store, routes)

  const initialState = store.getState()

  const getValuesFromSku = (sku: string) => {
    const values = {
      sku,
    }

    return values
  }

  if (initialMatches && initialMatches.recipeId) {
    store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
  } else if (initialMatches && isSkuSelector(initialState)) {
    const { sku } = initialMatches
    store.dispatch(controlTree.setValues(getValuesFromSku(sku)))
    store.dispatch(setOriginValues(controlTree))
  } else {
    store.dispatch(setOriginValues(controlTree))
  }

  let sku
  store.subscribe(() => {
    const matches = matchesSelector(store.getState())
    if (matches?.sku === undefined || matches.sku === sku) return
    sku = matches.sku
    store.dispatch(controlTree.commitChanges())
    store.dispatch(controlTree.setValues({ sku }))
  })

  createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <AppContextProvider
        initialValues={{
          patternPackage,
          vendor,
          products,
          designs,
          controlTree,
          designParts,
          colorDict,
          fonts,
          customFonts,
          placements,
        }}
      >
        <LoadingContextProvider>
          <RosterContextProvider>
            <App />
          </RosterContextProvider>
        </LoadingContextProvider>
      </AppContextProvider>
    </Provider>,
  )

  return { controlTree, store }
}
