// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "white",
    "name": "White",
    "hex": "#fcfcfc"
  },
  {
    "id": "black",
    "name": "Black",
    "hex": "#000000"
  },
  {
    "id": "red",
    "name": "Red",
    "hex": "#e62b2b"
  },
  {
    "id": "orange",
    "name": "Orange",
    "hex": "#ff600b"
  },
  {
    "id": "lightOrange",
    "name": "Light Orange",
    "hex": "#fd9d06"
  },
  {
    "id": "yellow",
    "name": "Yellow",
    "hex": "#fad900"
  },
  {
    "id": "lightGreen",
    "name": "Light Green",
    "hex": "#a3c418"
  },
  {
    "id": "green",
    "name": "Green",
    "hex": "#4bae30"
  },
  {
    "id": "mint",
    "name": "Mint",
    "hex": "#52b892"
  },
  {
    "id": "lightBlue",
    "name": "Light Blue",
    "hex": "#59c2f3"
  },
  {
    "id": "blue",
    "name": "Blue",
    "hex": "#2435ec"
  },
  {
    "id": "lilac",
    "name": "Lilac",
    "hex": "#8418c7"
  },
  {
    "id": "violet",
    "name": "Violet",
    "hex": "#bb2fcd"
  },
  {
    "id": "pink",
    "name": "Pink",
    "hex": "#f145d2"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "white": typed[i++],
  "black": typed[i++],
  "red": typed[i++],
  "orange": typed[i++],
  "lightOrange": typed[i++],
  "yellow": typed[i++],
  "lightGreen": typed[i++],
  "green": typed[i++],
  "mint": typed[i++],
  "lightBlue": typed[i++],
  "blue": typed[i++],
  "lilac": typed[i++],
  "violet": typed[i++],
  "pink": typed[i++]
} as const;

export { typed as COLORS };
