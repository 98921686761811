type Props = {
  children: React.ReactNode
}

const TileSet = (props: Props) => (
  // A bottom margin and not a gap in the flexbox because then we can't capture
  // mouse events in the empty space between the sections.
  <div className="mb-[8px] flex w-sidebar flex-wrap gap-[2px] overflow-auto rounded-[12px]">
    {props.children}
  </div>
)

export default TileSet
