// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CustomFontRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "custom:Gotham/700",
    "filetype": "otf"
  },
  {
    "id": "custom:Gotham Narrow/900",
    "filetype": "otf"
  },
  {
    "id": "custom:Varsity Team/700",
    "filetype": "otf"
  },
  {
    "id": "custom:Apollonia Pro/700",
    "filetype": "otf"
  },
  {
    "id": "custom:NBA Heat South Beach/regular",
    "filetype": "otf"
  },
  {
    "id": "custom:NCAA Arizona St Sun Devils/regular",
    "filetype": "otf"
  },
  {
    "id": "custom:NCAA Michigan St Spartans/regular",
    "filetype": "otf"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CustomFontRow[]>> = data;

export type CustomFont = typeof typed[number];

export const CUSTOM_FONT_INDEX_KEY = "id";
export type CustomFontIndexKey = "id";
export type CustomFontId = CustomFont["id"];

let i = 0;
export const CUSTOM_FONT_DICT = {
  "custom:Gotham/700": typed[i++],
  "custom:Gotham Narrow/900": typed[i++],
  "custom:Varsity Team/700": typed[i++],
  "custom:Apollonia Pro/700": typed[i++],
  "custom:NBA Heat South Beach/regular": typed[i++],
  "custom:NCAA Arizona St Sun Devils/regular": typed[i++],
  "custom:NCAA Michigan St Spartans/regular": typed[i++]
} as const;

export { typed as CUSTOM_FONTS };
