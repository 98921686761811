import { createMeta } from '../../../platform/spectacle-base/common/create-meta'
import { PRODUCT_DICT } from './sheets'

const {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
} = createMeta({ productDict: PRODUCT_DICT })

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
}
