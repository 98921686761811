// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FontRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "google:Maven Pro/regular"
  },
  {
    "id": "google:Maven Pro/500"
  },
  {
    "id": "google:Maven Pro/600"
  },
  {
    "id": "google:Maven Pro/700"
  },
  {
    "id": "google:Maven Pro/800"
  },
  {
    "id": "google:Maven Pro/900"
  },
  {
    "id": "google:Graduate/regular"
  },
  {
    "id": "google:Norican/regular"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FontRow[]>> = data;

export type Font = typeof typed[number];

export const FONT_INDEX_KEY = "id";
export type FontIndexKey = "id";
export type FontId = Font["id"];

let i = 0;
export const FONT_DICT = {
  "google:Maven Pro/regular": typed[i++],
  "google:Maven Pro/500": typed[i++],
  "google:Maven Pro/600": typed[i++],
  "google:Maven Pro/700": typed[i++],
  "google:Maven Pro/800": typed[i++],
  "google:Maven Pro/900": typed[i++],
  "google:Graduate/regular": typed[i++],
  "google:Norican/regular": typed[i++]
} as const;

export { typed as FONTS };
